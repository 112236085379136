export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "contents": {
          "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])}
        },
        "statuscodes": {
          "": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Error"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred"])}
          },
          "400": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Request"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we couldn't understand your request due to a syntax problem."])}
          },
          "401": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must login to access this page."])}
          },
          "402": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Required"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment is required to access this service, but we haven't defined yet how it works."])}
          },
          "403": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, you don't have permission to access this page."])}
          },
          "404": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! We're sorry, but the page you're looking for is not found."])}
          },
          "405": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method Not Allowed"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, but you can't use this method to access this resource."])}
          },
          "406": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Acceptable"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, we can't provide the information according to your preferences."])}
          },
          "407": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proxy Authentication Required"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access this page, you must first authenticate via a proxy."])}
          },
          "408": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Timeout"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request took too long to respond. Please try again later."])}
          },
          "409": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflict"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request conflicts with the current state of the server."])}
          },
          "410": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gone"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, but the page you're looking for has been permanently removed."])}
          },
          "411": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length Required"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request must specify the content length."])}
          },
          "412": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precondition Failed"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that some preconditions of your request are not met."])}
          },
          "413": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload Too Large"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request is too large for our server. Please reduce it and try again."])}
          },
          "414": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URI Too Long"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, but the address of the page you're looking for is too long."])}
          },
          "415": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported Media Type"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we can't handle the media type you requested."])}
          },
          "416": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Not Satisfiable"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested data range cannot be satisfied."])}
          },
          "417": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expectation Failed"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, but your expectations specified in the request cannot be satisfied by our server."])}
          },
          "418": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm a teapot"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server joke: I'm a teapot, so I can't make coffee."])}
          },
          "421": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misdirected Request"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Your request was sent to a server that is not able to respond. Please check the address you used."])}
          },
          "422": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unprocessable Entity"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request is well formed, but we encountered semantic errors. Please check it and try again."])}
          },
          "423": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The resource you are trying to access is currently locked."])}
          },
          "424": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed Dependency"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request failed because a previous request failed. Please try again later."])}
          },
          "425": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too Early"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not yet ready to process your request to prevent it from being replayed. Please try again later."])}
          },
          "426": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Required"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current protocol needs to be upgraded to process this request. Please upgrade and try again."])}
          },
          "428": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precondition Required"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request requires some preconditions. Please check your request and try again."])}
          },
          "429": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too Many Requests"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have sent too many requests in a short period of time. Please slow down and try again later."])}
          },
          "431": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Header Fields Too Large"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The header fields of your request are too long. Please reduce them and try again."])}
          },
          "451": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable For Legal Reasons"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are trying to access is blocked for legal reasons."])}
          },
          "500": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Server Error"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh oh, our server encountered an error that it doesn't know how to handle. We're working on fixing it."])}
          },
          "501": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Implemented"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server does not support the functionality required to respond to your request. It's not you, it's us."])}
          },
          "502": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Gateway"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server acts as a gateway and has received an invalid response from an upstream server. It's like receiving a bad response from an intermediary server."])}
          },
          "503": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Unavailable"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server is currently unable to handle the request because it is down for maintenance or overloaded. Please come back later."])}
          },
          "504": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway Timeout"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server, acting as a gateway, did not receive a response in time from another server. It's like a delivery delay problem."])}
          },
          "505": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Version Not Supported"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HTTP version you used in the request is not supported by the server. It might be time to upgrade."])}
          },
          "506": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variant Also Negotiates"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server has an incorrect internal configuration. It's a bit like we're having an internal debate about how to respond to your request."])}
          },
          "507": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient Storage"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server is unable to store the representation needed to complete the request. Our attic is a bit full, to put it simply."])}
          },
          "508": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loop Detected"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server has detected an infinite loop in trying to process the request. It's like we're going around in circles endlessly."])}
          },
          "510": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Extended"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information is required to satisfy the request. It's like we need a little more detail before we can respond."])}
          },
          "511": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network Authentication Required"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must authenticate to access the network. It's like showing your ID before entering."])}
          }
        }
      },
      "fr": {
        "contents": {
          "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accueil"])}
        },
        "statuscodes": {
          "": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur Inconnus"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inconnus est survenu"])}
          },
          "400": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande incorrecte"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous n'avons pas pu comprendre votre demande à cause d'un problème de syntaxe."])}
          },
          "401": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification nécessaire"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous connecter pour accéder à cette page."])}
          },
          "402": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement requis"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un paiement est nécessaire pour accéder à ce service, mais nous n'avons pas encore défini comment cela fonctionne."])}
          },
          "403": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès interdit"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, vous n'avez pas l'autorisation d'accéder à cette page."])}
          },
          "404": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page introuvable"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops ! Nous sommes désolés, mais la page que vous recherchez est introuvable."])}
          },
          "405": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode non autorisée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, mais vous ne pouvez pas utiliser cette méthode pour accéder à cette ressource."])}
          },
          "406": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas acceptable"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement, nous ne pouvons pas fournir les informations selon vos préférences."])}
          },
          "407": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification du proxy nécessaire"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accéder à cette page, vous devez d'abord vous authentifier via un proxy."])}
          },
          "408": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps d'attente dépassé"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre requête a pris trop de temps à répondre. Veuillez réessayer plus tard."])}
          },
          "409": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflit"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande entre en conflit avec l'état actuel du serveur."])}
          },
          "410": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page disparue"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, mais la page que vous recherchez a été définitivement supprimée."])}
          },
          "411": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur requise"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande doit spécifier la longueur du contenu."])}
          },
          "412": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précondition échouée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que certaines conditions préalables à votre demande ne sont pas remplies."])}
          },
          "413": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données trop volumineuses"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande est trop volumineuse pour notre serveur. Veuillez la réduire et réessayer."])}
          },
          "414": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URI trop long"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, mais l'adresse de la page que vous recherchez est trop longue."])}
          },
          "415": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de média non supporté"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous ne pouvons pas gérer le type de média que vous avez demandé."])}
          },
          "416": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plage non satisfaisante"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de données demandée ne peut pas être satisfaite."])}
          },
          "417": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attente échouée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, mais vos attentes spécifiées dans la requête ne peuvent être satisfaites par notre serveur."])}
          },
          "418": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis une théière"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petite blague du serveur : je suis une théière, je ne peux donc pas préparer de café."])}
          },
          "421": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requête mal orientée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups ! Votre requête a été envoyée à un serveur qui n'est pas en mesure de répondre. Veuillez vérifier l'adresse que vous avez utilisée."])}
          },
          "422": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entité non traitable"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre requête est bien formulée, mais nous avons rencontré des erreurs de sens. Veuillez la vérifier et réessayer."])}
          },
          "423": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressource verrouillée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ressource que vous essayez d'accéder est actuellement verrouillée."])}
          },
          "424": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendance échouée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre requête a échoué en raison de l'échec d'une requête précédente. Veuillez réessayer plus tard."])}
          },
          "425": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop tôt"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne sommes pas encore prêts à traiter votre requête pour éviter qu'elle ne soit rejouée. Veuillez réessayer plus tard."])}
          },
          "426": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour nécessaire"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre protocole actuel doit être mis à jour pour traiter cette requête. Veuillez mettre à jour et réessayer."])}
          },
          "428": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précondition requise"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre requête nécessite certaines conditions préalables. Veuillez vérifier votre requête et réessayer."])}
          },
          "429": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop de requêtes"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez envoyé trop de requêtes en peu de temps. Veuillez ralentir et réessayer plus tard."])}
          },
          "431": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs d'en-tête de la requête trop grands"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champs d'en-tête de votre requête sont trop longs. Veuillez les réduire et réessayer."])}
          },
          "451": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indisponible pour des raisons légales"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page à laquelle vous essayez d'accéder est bloquée pour des raisons juridiques."])}
          },
          "500": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur interne du serveur"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh oh, notre serveur a rencontré une erreur qu'il ne sait pas comment gérer. Nous travaillons à le résoudre."])}
          },
          "501": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non implémenté"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur ne prend pas en charge la fonctionnalité requise pour répondre à votre demande. Ce n'est pas vous, c'est nous."])}
          },
          "502": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaise passerelle"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur agit comme une passerelle et a reçu une réponse invalide de la part d'un serveur en amont. C'est comme recevoir une mauvaise réponse d'un serveur intermédiaire."])}
          },
          "503": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service non disponible"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur est actuellement incapable de gérer la demande car il est en maintenance ou surchargé. S'il vous plaît, revenez plus tard."])}
          },
          "504": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délai de la passerelle"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur, agissant en tant que passerelle, n'a pas reçu de réponse à temps d'un autre serveur. C'est comme un problème de retard de livraison."])}
          },
          "505": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version HTTP non supportée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La version HTTP que vous avez utilisée dans la requête n'est pas supportée par le serveur. Il serait peut-être temps de mettre à jour."])}
          },
          "506": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variante également négocie"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur a une configuration interne erronée. C'est un peu comme si nous avions un débat interne pour décider comment répondre à votre requête."])}
          },
          "507": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockage insuffisant"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur n'est pas en mesure de stocker la représentation nécessaire pour compléter la requête. Notre grenier est un peu plein, pour le dire simplement."])}
          },
          "508": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boucle détectée"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur a détecté une boucle infinie en essayant de traiter la requête. C'est comme si nous tournions en rond sans fin."])}
          },
          "510": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas étendu"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des informations supplémentaires sont nécessaires pour satisfaire la requête. C'est comme si nous avions besoin d'un peu plus de détails avant de pouvoir répondre."])}
          },
          "511": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification réseau nécessaire"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous authentifier pour avoir accès au réseau. C'est comme montrer votre carte d'identité avant d'entrer."])}
          }
        }
      },
      "de": {
        "contents": {
          "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])}
        },
        "statuscodes": {
          "": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein unbekannter Fehler ist aufgetreten"])}
          },
          "400": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Anfrage"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider konnten wir Ihre Anfrage aufgrund eines Syntaxproblems nicht verstehen."])}
          },
          "401": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht autorisiert"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen sich anmelden, um auf diese Seite zugreifen zu können."])}
          },
          "402": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung erforderlich"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für den Zugriff auf diesen Dienst ist eine Zahlung erforderlich, aber wir haben noch nicht definiert, wie er funktioniert."])}
          },
          "403": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verboten"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider haben Sie keine Berechtigung, auf diese Seite zuzugreifen."])}
          },
          "404": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Wir entschuldigen uns, aber die von Ihnen gesuchte Seite wurde nicht gefunden."])}
          },
          "405": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methode nicht erlaubt"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider können Sie diese Methode nicht verwenden, um auf diese Ressource zuzugreifen."])}
          },
          "406": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht akzeptabel"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider können wir die Informationen nicht nach Ihren Wünschen bereitstellen."])}
          },
          "407": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proxy-Authentifizierung erforderlich"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um auf diese Seite zugreifen zu können, müssen Sie sich zuerst über einen Proxy authentifizieren."])}
          },
          "408": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitüberschreitung der Anfrage"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage hat zu lange gedauert, um zu antworten. Bitte versuchen Sie es später noch einmal."])}
          },
          "409": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konflikt"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage steht im Widerspruch zum aktuellen Zustand des Servers."])}
          },
          "410": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weg"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider wurde die von Ihnen gesuchte Seite dauerhaft entfernt."])}
          },
          "411": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge erforderlich"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage muss die Länge des Inhalts angeben."])}
          },
          "412": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussetzung fehlgeschlagen"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es scheint, dass einige Voraussetzungen Ihrer Anfrage nicht erfüllt sind."])}
          },
          "413": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload zu groß"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage ist für unseren Server zu groß. Bitte reduzieren Sie sie und versuchen Sie es erneut."])}
          },
          "414": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URI zu lang"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider ist die Adresse der von Ihnen gesuchten Seite zu lang."])}
          },
          "415": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht unterstützter Medientyp"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider können wir den von Ihnen angeforderten Medientyp nicht verarbeiten."])}
          },
          "416": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich nicht zufriedenstellend"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angeforderte Datenbereich kann nicht erfüllt werden."])}
          },
          "417": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartung fehlgeschlagen"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider können Ihre in der Anfrage angegebenen Erwartungen von unserem Server nicht erfüllt werden."])}
          },
          "418": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin eine Teekanne"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverwitz: Ich bin eine Teekanne, also kann ich keinen Kaffee zubereiten."])}
          },
          "421": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlgeleitete Anfrage"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Ihre Anfrage wurde an einen Server gesendet, der nicht in der Lage ist, zu antworten. Bitte überprüfen Sie die von Ihnen verwendete Adresse."])}
          },
          "422": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverarbeitbare Entität"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage ist gut formuliert, aber wir sind auf semantische Fehler gestoßen. Bitte überprüfen Sie es und versuchen Sie es erneut."])}
          },
          "423": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ressource, auf die Sie zugreifen möchten, ist derzeit gesperrt."])}
          },
          "424": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abhängigkeit fehlgeschlagen"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage ist fehlgeschlagen, weil eine vorherige Anfrage fehlgeschlagen ist. Bitte versuchen Sie es später noch einmal."])}
          },
          "425": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu früh"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind noch nicht bereit, Ihre Anfrage zu bearbeiten, um zu verhindern, dass sie wiederholt wird. Bitte versuchen Sie es später noch einmal."])}
          },
          "426": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade erforderlich"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr aktuelles Protokoll muss aktualisiert werden, um diese Anfrage zu verarbeiten. Bitte aktualisieren Sie und versuchen Sie es erneut."])}
          },
          "428": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussetzung erforderlich"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Anfrage erfordert einige Voraussetzungen. Bitte überprüfen Sie Ihre Anfrage und versuchen Sie es erneut."])}
          },
          "429": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu viele Anfragen"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben in kurzer Zeit zu viele Anfragen gesendet. Bitte verlangsamen Sie sich und versuchen Sie es später noch einmal."])}
          },
          "431": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anforderungskopffelder zu groß"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Header-Felder Ihrer Anfrage sind zu lang. Bitte reduzieren Sie sie und versuchen Sie es erneut."])}
          },
          "451": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus rechtlichen Gründen nicht verfügbar"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite, auf die Sie zugreifen möchten, ist aus rechtlichen Gründen blockiert."])}
          },
          "500": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner Serverfehler"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh oh, unser Server hat einen Fehler festgestellt, den er nicht weiß, wie er ihn behandeln soll. Wir arbeiten daran, es zu beheben."])}
          },
          "501": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht implementiert"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server unterstützt die für Ihre Anfrage erforderliche Funktionalität nicht. Es liegt nicht an Ihnen, es liegt an uns."])}
          },
          "502": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlechtes Gateway"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server fungiert als Gateway und hat eine ungültige Antwort von einem Upstream-Server erhalten. Es ist wie eine schlechte Antwort von einem Zwischenserver zu erhalten."])}
          },
          "503": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst nicht verfügbar"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server kann die Anfrage derzeit nicht bearbeiten, da er wegen Wartungsarbeiten oder Überlastung nicht verfügbar ist. Bitte kommen Sie später wieder."])}
          },
          "504": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway-Zeitüberschreitung"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server, der als Gateway fungiert, hat keine rechtzeitige Antwort von einem anderen Server erhalten. Es ist wie ein Problem mit der Lieferverzögerung."])}
          },
          "505": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP-Version nicht unterstützt"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen in der Anfrage verwendete HTTP-Version wird vom Server nicht unterstützt. Es ist vielleicht an der Zeit, ein Upgrade durchzuführen."])}
          },
          "506": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variante verhandelt auch"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server hat eine falsche interne Konfiguration. Es ist ein bisschen so, als würden wir intern darüber diskutieren, wie wir auf Ihre Anfrage antworten sollen."])}
          },
          "507": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicherplatz nicht ausreichend"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server ist nicht in der Lage, die zur Bearbeitung der Anfrage erforderliche Darstellung zu speichern. Unser Dachboden ist etwas voll, um es einfach auszudrücken."])}
          },
          "508": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schleife erkannt"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server hat eine Endlosschleife beim Versuch, die Anfrage zu verarbeiten, erkannt. Es ist wie wenn wir endlos im Kreis laufen."])}
          },
          "510": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht erweitert"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen sind erforderlich, um die Anfrage zu erfüllen. Es ist wie wenn wir ein paar mehr Details benötigen, bevor wir antworten können."])}
          },
          "511": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerkauthentifizierung erforderlich"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen sich authentifizieren, um auf das Netzwerk zugreifen zu können. Es ist wie eine ID vor dem Betreten zu zeigen."])}
          }
        }
      },
      "it": {
        "contents": {
          "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])}
        },
        "statuscodes": {
          "": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore sconosciuto"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore sconosciuto"])}
          },
          "400": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta non valida"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, non siamo riusciti a comprendere la tua richiesta a causa di un problema di sintassi."])}
          },
          "401": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non autorizzato"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi effettuare l'accesso per accedere a questa pagina."])}
          },
          "402": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento richiesto"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario un pagamento per accedere a questo servizio, ma non abbiamo ancora definito come funziona."])}
          },
          "403": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietato"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, non hai il permesso di accedere a questa pagina."])}
          },
          "404": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina non trovata"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Ci dispiace, ma la pagina che stai cercando non è stata trovata."])}
          },
          "405": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo non consentito"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, ma non puoi utilizzare questo metodo per accedere a questa risorsa."])}
          },
          "406": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non accettabile"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purtroppo non possiamo fornire le informazioni secondo le tue preferenze."])}
          },
          "407": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione proxy richiesta"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per accedere a questa pagina, devi prima autenticarti tramite un proxy."])}
          },
          "408": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta scaduta"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta ha impiegato troppo tempo a rispondere. Riprova più tardi."])}
          },
          "409": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conflitto"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta è in conflitto con lo stato attuale del server."])}
          },
          "410": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andato"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, ma la pagina che stai cercando è stata rimossa definitivamente."])}
          },
          "411": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza richiesta"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta deve specificare la lunghezza del contenuto."])}
          },
          "412": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precondizione fallita"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembra che alcune precondizioni della tua richiesta non siano soddisfatte."])}
          },
          "413": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload troppo grande"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta è troppo grande per il nostro server. Riducilo e riprova."])}
          },
          "414": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URI troppo lungo"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, ma l'indirizzo della pagina che stai cercando è troppo lungo."])}
          },
          "415": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di supporto non supportato"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, non possiamo gestire il tipo di supporto richiesto."])}
          },
          "416": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervallo non soddisfacente"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'intervallo di dati richiesto non può essere soddisfatto."])}
          },
          "417": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspettativa fallita"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, ma le tue aspettative specificate nella richiesta non possono essere soddisfatte dal nostro server."])}
          },
          "418": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono una teiera"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scherzo del server: Sono una teiera, quindi non posso preparare il caffè."])}
          },
          "421": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta errata"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! La tua richiesta è stata inviata a un server che non è in grado di rispondere. Controlla l'indirizzo che hai usato."])}
          },
          "422": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entità non elaborabile"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta è ben formulata, ma abbiamo riscontrato errori semantici. Controllalo e riprova."])}
          },
          "423": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloccato"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La risorsa a cui stai cercando di accedere è attualmente bloccata."])}
          },
          "424": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipendenza fallita"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta è fallita perché una richiesta precedente è fallita. Riprova più tardi."])}
          },
          "425": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troppo presto"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non siamo ancora pronti a elaborare la tua richiesta per evitare che venga riprodotta. Riprova più tardi."])}
          },
          "426": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornamento richiesto"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo protocollo corrente deve essere aggiornato per elaborare questa richiesta. Aggiorna e riprova."])}
          },
          "428": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precondizione richiesta"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta richiede alcune precondizioni. Controlla la tua richiesta e riprova."])}
          },
          "429": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troppe richieste"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai inviato troppe richieste in un breve periodo di tempo. Rallenta e riprova più tardi."])}
          },
          "431": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi di intestazione della richiesta troppo grandi"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I campi di intestazione della tua richiesta sono troppo lunghi. Riducili e riprova."])}
          },
          "451": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponibile per motivi legali"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pagina a cui stai cercando di accedere è bloccata per motivi legali."])}
          },
          "500": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore interno del server"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh oh, il nostro server ha riscontrato un errore che non sa come gestire. Stiamo lavorando per risolverlo."])}
          },
          "501": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non implementato"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il server non supporta la funzionalità richiesta per rispondere alla tua richiesta. Non sei tu, siamo noi."])}
          },
          "502": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway non valido"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il server funge da gateway e ha ricevuto una risposta non valida da un server upstream. È come ricevere una risposta errata da un server intermedio."])}
          },
          "503": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio non disponibile"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il server non è attualmente in grado di gestire la richiesta perché è in manutenzione o sovraccarico. Per favore torna più tardi."])}
          },
          "504": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout del gateway"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il server, agendo come gateway, non ha ricevuto una risposta in tempo da un altro server. È come un problema di ritardo nella consegna."])}
          },
          "505": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versione HTTP non supportata"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La versione HTTP utilizzata nella richiesta non è supportata dal server. Potrebbe essere il momento di aggiornare."])}
          },
          "506": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variante negozia anche"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il server ha una configurazione interna errata. È un po' come se avessimo un dibattito interno su come rispondere alla tua richiesta."])}
          },
          "507": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazio di archiviazione insufficiente"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il server non è in grado di archiviare la rappresentazione necessaria per completare la richiesta. Il nostro sottotetto è un po' pieno, per dirla in parole povere."])}
          },
          "508": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rilevato loop"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il server ha rilevato un loop infinito nel tentativo di elaborare la richiesta. È come se girassimo in tondo all'infinito."])}
          },
          "510": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non esteso"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono necessarie informazioni aggiuntive per soddisfare la richiesta. È come se avessimo bisogno di un po' più di dettagli prima di poter rispondere."])}
          },
          "511": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione di rete richiesta"])},
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario autenticarsi per accedere alla rete. È come mostrare la tua carta d'identità prima di entrare."])}
          }
        }
      }
    }
  })
}

<template>
  <q-page class="error-page text-center q-pa-md flex flex-center">
    <div>
      <div class="text-h2 q-mb-md text-primary">
        {{ t(`statuscodes.${route.params.code}.error`) }}
      </div>
      <p class="text-warning text-bold">
        {{ t(`statuscodes.${route.params.code}.message`) }}
      </p>
      <div class="styled-text text-isometric text-warning">
        {{ route.params.code }}
      </div>
    </div>
    <nav class="d-block full-width">
      <q-btn
        :to="$localePath('/')"
        color="primary"
        :label="t('contents.home')"
        icon="home"
        class="q-mt-md"
      />
    </nav>
  </q-page>
</template>
<i18n>
  {
    "en-US": {
      "contents": {
        "home": "home"
      },
      "statuscodes": {
        "" : {
          "error": "Unknown Error",
          "message": "An unknown error has occurred"
        },
        "400": {
          "error": "Bad Request",
          "message": "Sorry, we couldn't understand your request due to a syntax problem."
        },
        "401": {
          "error": "Unauthorized",
          "message": "You must login to access this page."
        },
        "402": {
          "error": "Payment Required",
          "message": "Payment is required to access this service, but we haven't defined yet how it works."
        },
        "403": {
          "error": "Forbidden",
          "message": "Sorry, you don't have permission to access this page."
        },
        "404": {
          "error": "Page Not Found",
          "message": "Oops! We're sorry, but the page you're looking for is not found."
        },
        "405": {
          "error": "Method Not Allowed",
          "message": "Sorry, but you can't use this method to access this resource."
        },
        "406": {
          "error": "Not Acceptable",
          "message": "Unfortunately, we can't provide the information according to your preferences."
        },
        "407": {
          "error": "Proxy Authentication Required",
          "message": "To access this page, you must first authenticate via a proxy."
        },
        "408": {
          "error": "Request Timeout",
          "message": "Your request took too long to respond. Please try again later."
        },
        "409": {
          "error": "Conflict",
          "message": "Your request conflicts with the current state of the server."
        },
        "410": {
          "error": "Gone",
          "message": "Sorry, but the page you're looking for has been permanently removed."
        },
        "411": {
          "error": "Length Required",
          "message": "Your request must specify the content length."
        },
        "412": {
          "error": "Precondition Failed",
          "message": "It seems that some preconditions of your request are not met."
        },
        "413": {
          "error": "Payload Too Large",
          "message": "Your request is too large for our server. Please reduce it and try again."
        },
        "414": {
          "error": "URI Too Long",
          "message": "Sorry, but the address of the page you're looking for is too long."
        },
        "415": {
          "error": "Unsupported Media Type",
          "message": "Sorry, we can't handle the media type you requested."
        },
        "416": {
          "error": "Range Not Satisfiable",
          "message": "The requested data range cannot be satisfied."
        },
        "417": {
          "error": "Expectation Failed",
          "message": "Sorry, but your expectations specified in the request cannot be satisfied by our server."
        },
        "418": {
          "error": "I'm a teapot",
          "message": "Server joke: I'm a teapot, so I can't make coffee."
        },
        "421": {
          "error": "Misdirected Request",
          "message": "Oops! Your request was sent to a server that is not able to respond. Please check the address you used."
        },
        "422": {
          "error": "Unprocessable Entity",
          "message": "Your request is well formed, but we encountered semantic errors. Please check it and try again."
        },
        "423": {
          "error": "Locked",
          "message": "The resource you are trying to access is currently locked."
        },
        "424": {
          "error": "Failed Dependency",
          "message": "Your request failed because a previous request failed. Please try again later."
        },
        "425": {
          "error": "Too Early",
          "message": "We are not yet ready to process your request to prevent it from being replayed. Please try again later."
        },
        "426": {
          "error": "Upgrade Required",
          "message": "Your current protocol needs to be upgraded to process this request. Please upgrade and try again."
        },
        "428": {
          "error": "Precondition Required",
          "message": "Your request requires some preconditions. Please check your request and try again."
        },
        "429": {
          "error": "Too Many Requests",
          "message": "You have sent too many requests in a short period of time. Please slow down and try again later."
        },
        "431": {
          "error": "Request Header Fields Too Large",
          "message": "The header fields of your request are too long. Please reduce them and try again."
        },
        "451": {
          "error": "Unavailable For Legal Reasons",
          "message": "The page you are trying to access is blocked for legal reasons."
        },
        "500": {
          "error": "Internal Server Error",
          "message": "Oh oh, our server encountered an error that it doesn't know how to handle. We're working on fixing it."
        },
        "501": {
          "error": "Not Implemented",
          "message": "The server does not support the functionality required to respond to your request. It's not you, it's us."
        },
        "502": {
          "error": "Bad Gateway",
          "message": "The server acts as a gateway and has received an invalid response from an upstream server. It's like receiving a bad response from an intermediary server."
        },
        "503": {
          "error": "Service Unavailable",
          "message": "The server is currently unable to handle the request because it is down for maintenance or overloaded. Please come back later."
        },
        "504": {
          "error": "Gateway Timeout",
          "message": "The server, acting as a gateway, did not receive a response in time from another server. It's like a delivery delay problem."
        },
        "505": {
          "error": "HTTP Version Not Supported",
          "message": "The HTTP version you used in the request is not supported by the server. It might be time to upgrade."
        },
        "506": {
          "error": "Variant Also Negotiates",
          "message": "The server has an incorrect internal configuration. It's a bit like we're having an internal debate about how to respond to your request."
        },
        "507": {
          "error": "Insufficient Storage",
          "message": "The server is unable to store the representation needed to complete the request. Our attic is a bit full, to put it simply."
        },
        "508": {
          "error": "Loop Detected",
          "message": "The server has detected an infinite loop in trying to process the request. It's like we're going around in circles endlessly."
        },
        "510": {
          "error": "Not Extended",
          "message": "Additional information is required to satisfy the request. It's like we need a little more detail before we can respond."
        },
        "511": {
          "error": "Network Authentication Required",
          "message": "You must authenticate to access the network. It's like showing your ID before entering."
        },
      },
    },
    "fr": {
      "contents": {
        "home": "accueil"
      },
      "statuscodes": {
        "" : {
          "error": "Erreur Inconnus",
          "message": "Une erreur inconnus est survenu"
        },
        "400": {
          "error": "Demande incorrecte",
          "message": "Désolé, nous n'avons pas pu comprendre votre demande à cause d'un problème de syntaxe."
        },
        "401": {
          "error": "Authentification nécessaire",
          "message": "Vous devez vous connecter pour accéder à cette page."
        },
        "402": {
          "error": "Paiement requis",
          "message": "Un paiement est nécessaire pour accéder à ce service, mais nous n'avons pas encore défini comment cela fonctionne."
        },
        "403": {
          "error": "Accès interdit",
          "message": "Désolé, vous n'avez pas l'autorisation d'accéder à cette page."
        },
        "404": {
          "error": "Page introuvable",
          "message": "Oops ! Nous sommes désolés, mais la page que vous recherchez est introuvable."
        },
        "405": {
          "error": "Méthode non autorisée",
          "message": "Désolé, mais vous ne pouvez pas utiliser cette méthode pour accéder à cette ressource."
        },
        "406": {
          "error": "Pas acceptable",
          "message": "Malheureusement, nous ne pouvons pas fournir les informations selon vos préférences."
        },
        "407": {
          "error": "Authentification du proxy nécessaire",
          "message": "Pour accéder à cette page, vous devez d'abord vous authentifier via un proxy."
        },
        "408": {
          "error": "Temps d'attente dépassé",
          "message": "Votre requête a pris trop de temps à répondre. Veuillez réessayer plus tard."
        },
        "409": {
          "error": "Conflit",
          "message": "Votre demande entre en conflit avec l'état actuel du serveur."
        },
        "410": {
          "error": "Page disparue",
          "message": "Désolé, mais la page que vous recherchez a été définitivement supprimée."
        },
        "411": {
          "error": "Longueur requise",
          "message": "Votre demande doit spécifier la longueur du contenu."
        },
        "412": {
          "error": "Précondition échouée",
          "message": "Il semble que certaines conditions préalables à votre demande ne sont pas remplies."
        },
        "413": {
          "error": "Données trop volumineuses",
          "message": "Votre demande est trop volumineuse pour notre serveur. Veuillez la réduire et réessayer."
        },
        "414": {
          "error": "URI trop long",
          "message": "Désolé, mais l'adresse de la page que vous recherchez est trop longue."
        },
        "415": {
          "error": "Type de média non supporté",
          "message": "Désolé, nous ne pouvons pas gérer le type de média que vous avez demandé."
        },
        "416": {
          "error": "Plage non satisfaisante",
          "message": "La plage de données demandée ne peut pas être satisfaite."
        },
        "417": {
          "error": "Attente échouée",
          "message": "Désolé, mais vos attentes spécifiées dans la requête ne peuvent être satisfaites par notre serveur."
        },
        "418": {
          "error": "Je suis une théière",
          "message": "Petite blague du serveur : je suis une théière, je ne peux donc pas préparer de café."
        },
        "421": {
          "error": "Requête mal orientée",
          "message": "Oups ! Votre requête a été envoyée à un serveur qui n'est pas en mesure de répondre. Veuillez vérifier l'adresse que vous avez utilisée."
        },
        "422": {
          "error": "Entité non traitable",
          "message": "Votre requête est bien formulée, mais nous avons rencontré des erreurs de sens. Veuillez la vérifier et réessayer."
        },
        "423": {
          "error": "Ressource verrouillée",
          "message": "La ressource que vous essayez d'accéder est actuellement verrouillée."
        },
        "424": {
          "error": "Dépendance échouée",
          "message": "Votre requête a échoué en raison de l'échec d'une requête précédente. Veuillez réessayer plus tard."
        },
        "425": {
          "error": "Trop tôt",
          "message": "Nous ne sommes pas encore prêts à traiter votre requête pour éviter qu'elle ne soit rejouée. Veuillez réessayer plus tard."
        },
        "426": {
          "error": "Mise à jour nécessaire",
          "message": "Votre protocole actuel doit être mis à jour pour traiter cette requête. Veuillez mettre à jour et réessayer."
        },
        "428": {
          "error": "Précondition requise",
          "message": "Votre requête nécessite certaines conditions préalables. Veuillez vérifier votre requête et réessayer."
        },
        "429": {
          "error": "Trop de requêtes",
          "message": "Vous avez envoyé trop de requêtes en peu de temps. Veuillez ralentir et réessayer plus tard."
        },
        "431": {
          "error": "Champs d'en-tête de la requête trop grands",
          "message": "Les champs d'en-tête de votre requête sont trop longs. Veuillez les réduire et réessayer."
        },
        "451": {
          "error": "Indisponible pour des raisons légales",
          "message": "La page à laquelle vous essayez d'accéder est bloquée pour des raisons juridiques."
        },
        "500": {
          "error": "Erreur interne du serveur",
          "message": "Oh oh, notre serveur a rencontré une erreur qu'il ne sait pas comment gérer. Nous travaillons à le résoudre."
        },
        "501": {
          "error": "Non implémenté",
          "message": "Le serveur ne prend pas en charge la fonctionnalité requise pour répondre à votre demande. Ce n'est pas vous, c'est nous."
        },
        "502": {
          "error": "Mauvaise passerelle",
          "message": "Le serveur agit comme une passerelle et a reçu une réponse invalide de la part d'un serveur en amont. C'est comme recevoir une mauvaise réponse d'un serveur intermédiaire."
        },
        "503": {
          "error": "Service non disponible",
          "message": "Le serveur est actuellement incapable de gérer la demande car il est en maintenance ou surchargé. S'il vous plaît, revenez plus tard."
        },
        "504": {
          "error": "Délai de la passerelle",
          "message": "Le serveur, agissant en tant que passerelle, n'a pas reçu de réponse à temps d'un autre serveur. C'est comme un problème de retard de livraison."
        },
        "505": {
          "error": "Version HTTP non supportée",
          "message": "La version HTTP que vous avez utilisée dans la requête n'est pas supportée par le serveur. Il serait peut-être temps de mettre à jour."
        },
        "506": {
          "error": "Variante également négocie",
          "message": "Le serveur a une configuration interne erronée. C'est un peu comme si nous avions un débat interne pour décider comment répondre à votre requête."
        },
        "507": {
          "error": "Stockage insuffisant",
          "message": "Le serveur n'est pas en mesure de stocker la représentation nécessaire pour compléter la requête. Notre grenier est un peu plein, pour le dire simplement."
        },
        "508": {
          "error": "Boucle détectée",
          "message": "Le serveur a détecté une boucle infinie en essayant de traiter la requête. C'est comme si nous tournions en rond sans fin."
        },
        "510": {
          "error": "Pas étendu",
          "message": "Des informations supplémentaires sont nécessaires pour satisfaire la requête. C'est comme si nous avions besoin d'un peu plus de détails avant de pouvoir répondre."
        },
        "511": {
          "error": "Authentification réseau nécessaire",
          "message": "Vous devez vous authentifier pour avoir accès au réseau. C'est comme montrer votre carte d'identité avant d'entrer."
        },
      },
    },
    "de": {
      "contents": {
        "home": "Startseite"
      },
      "statuscodes": {
        "" : {
          "error": "Unbekannter Fehler",
          "message": "Ein unbekannter Fehler ist aufgetreten"
        },
        "400": {
          "error": "Falsche Anfrage",
          "message": "Leider konnten wir Ihre Anfrage aufgrund eines Syntaxproblems nicht verstehen."
        },
        "401": {
          "error": "Nicht autorisiert",
          "message": "Sie müssen sich anmelden, um auf diese Seite zugreifen zu können."
        },
        "402": {
          "error": "Zahlung erforderlich",
          "message": "Für den Zugriff auf diesen Dienst ist eine Zahlung erforderlich, aber wir haben noch nicht definiert, wie er funktioniert."
        },
        "403": {
          "error": "Verboten",
          "message": "Leider haben Sie keine Berechtigung, auf diese Seite zuzugreifen."
        },
        "404": {
          "error": "Seite nicht gefunden",
          "message": "Oops! Wir entschuldigen uns, aber die von Ihnen gesuchte Seite wurde nicht gefunden."
        },
        "405": {
          "error": "Methode nicht erlaubt",
          "message": "Leider können Sie diese Methode nicht verwenden, um auf diese Ressource zuzugreifen."
        },
        "406": {
          "error": "Nicht akzeptabel",
          "message": "Leider können wir die Informationen nicht nach Ihren Wünschen bereitstellen."
        },
        "407": {
          "error": "Proxy-Authentifizierung erforderlich",
          "message": "Um auf diese Seite zugreifen zu können, müssen Sie sich zuerst über einen Proxy authentifizieren."
        },
        "408": {
          "error": "Zeitüberschreitung der Anfrage",
          "message": "Ihre Anfrage hat zu lange gedauert, um zu antworten. Bitte versuchen Sie es später noch einmal."
        },
        "409": {
          "error": "Konflikt",
          "message": "Ihre Anfrage steht im Widerspruch zum aktuellen Zustand des Servers."
        },
        "410": {
          "error": "Weg",
          "message": "Leider wurde die von Ihnen gesuchte Seite dauerhaft entfernt."
        },
        "411": {
          "error": "Länge erforderlich",
          "message": "Ihre Anfrage muss die Länge des Inhalts angeben."
        },
        "412": {
          "error": "Voraussetzung fehlgeschlagen",
          "message": "Es scheint, dass einige Voraussetzungen Ihrer Anfrage nicht erfüllt sind."
        },
        "413": {
          "error": "Payload zu groß",
          "message": "Ihre Anfrage ist für unseren Server zu groß. Bitte reduzieren Sie sie und versuchen Sie es erneut."
        },
        "414": {
          "error": "URI zu lang",
          "message": "Leider ist die Adresse der von Ihnen gesuchten Seite zu lang."
        },
        "415": {
          "error": "Nicht unterstützter Medientyp",
          "message": "Leider können wir den von Ihnen angeforderten Medientyp nicht verarbeiten."
        },
        "416": {
          "error": "Bereich nicht zufriedenstellend",
          "message": "Die angeforderte Datenbereich kann nicht erfüllt werden."
        },
        "417": {
          "error": "Erwartung fehlgeschlagen",
          "message": "Leider können Ihre in der Anfrage angegebenen Erwartungen von unserem Server nicht erfüllt werden."
        },
        "418": {
          "error": "Ich bin eine Teekanne",
          "message": "Serverwitz: Ich bin eine Teekanne, also kann ich keinen Kaffee zubereiten."
        },
        "421": {
          "error": "Fehlgeleitete Anfrage",
          "message": "Oops! Ihre Anfrage wurde an einen Server gesendet, der nicht in der Lage ist, zu antworten. Bitte überprüfen Sie die von Ihnen verwendete Adresse."
        },
        "422": {
          "error": "Unverarbeitbare Entität",
          "message": "Ihre Anfrage ist gut formuliert, aber wir sind auf semantische Fehler gestoßen. Bitte überprüfen Sie es und versuchen Sie es erneut."
        },
        "423": {
          "error": "Gesperrt",
          "message": "Die Ressource, auf die Sie zugreifen möchten, ist derzeit gesperrt."
        },
        "424": {
          "error": "Abhängigkeit fehlgeschlagen",
          "message": "Ihre Anfrage ist fehlgeschlagen, weil eine vorherige Anfrage fehlgeschlagen ist. Bitte versuchen Sie es später noch einmal."
        },
        "425": {
          "error": "Zu früh",
          "message": "Wir sind noch nicht bereit, Ihre Anfrage zu bearbeiten, um zu verhindern, dass sie wiederholt wird. Bitte versuchen Sie es später noch einmal."
        },
        "426": {
          "error": "Upgrade erforderlich",
          "message": "Ihr aktuelles Protokoll muss aktualisiert werden, um diese Anfrage zu verarbeiten. Bitte aktualisieren Sie und versuchen Sie es erneut."
        },
        "428": {
          "error": "Voraussetzung erforderlich",
          "message": "Ihre Anfrage erfordert einige Voraussetzungen. Bitte überprüfen Sie Ihre Anfrage und versuchen Sie es erneut."
        },
        "429": {
          "error": "Zu viele Anfragen",
          "message": "Sie haben in kurzer Zeit zu viele Anfragen gesendet. Bitte verlangsamen Sie sich und versuchen Sie es später noch einmal."
        },
        "431": {
          "error": "Anforderungskopffelder zu groß",
          "message": "Die Header-Felder Ihrer Anfrage sind zu lang. Bitte reduzieren Sie sie und versuchen Sie es erneut."
        },
        "451": {
          "error": "Aus rechtlichen Gründen nicht verfügbar",
          "message": "Die Seite, auf die Sie zugreifen möchten, ist aus rechtlichen Gründen blockiert."
        },
        "500": {
          "error": "Interner Serverfehler",
          "message": "Oh oh, unser Server hat einen Fehler festgestellt, den er nicht weiß, wie er ihn behandeln soll. Wir arbeiten daran, es zu beheben."
        },
        "501": {
          "error": "Nicht implementiert",
          "message": "Der Server unterstützt die für Ihre Anfrage erforderliche Funktionalität nicht. Es liegt nicht an Ihnen, es liegt an uns."
        },
        "502": {
          "error": "Schlechtes Gateway",
          "message": "Der Server fungiert als Gateway und hat eine ungültige Antwort von einem Upstream-Server erhalten. Es ist wie eine schlechte Antwort von einem Zwischenserver zu erhalten."
        },
        "503": {
          "error": "Dienst nicht verfügbar",
          "message": "Der Server kann die Anfrage derzeit nicht bearbeiten, da er wegen Wartungsarbeiten oder Überlastung nicht verfügbar ist. Bitte kommen Sie später wieder."
        },
        "504": {
          "error": "Gateway-Zeitüberschreitung",
          "message": "Der Server, der als Gateway fungiert, hat keine rechtzeitige Antwort von einem anderen Server erhalten. Es ist wie ein Problem mit der Lieferverzögerung."
        },
        "505": {
          "error": "HTTP-Version nicht unterstützt",
          "message": "Die von Ihnen in der Anfrage verwendete HTTP-Version wird vom Server nicht unterstützt. Es ist vielleicht an der Zeit, ein Upgrade durchzuführen."
        },
        "506": {
          "error": "Variante verhandelt auch",
          "message": "Der Server hat eine falsche interne Konfiguration. Es ist ein bisschen so, als würden wir intern darüber diskutieren, wie wir auf Ihre Anfrage antworten sollen."
        },
        "507": {
          "error": "Speicherplatz nicht ausreichend",
          "message": "Der Server ist nicht in der Lage, die zur Bearbeitung der Anfrage erforderliche Darstellung zu speichern. Unser Dachboden ist etwas voll, um es einfach auszudrücken."
        },
        "508": {
          "error": "Schleife erkannt",
          "message": "Der Server hat eine Endlosschleife beim Versuch, die Anfrage zu verarbeiten, erkannt. Es ist wie wenn wir endlos im Kreis laufen."
        },
        "510": {
          "error": "Nicht erweitert",
          "message": "Zusätzliche Informationen sind erforderlich, um die Anfrage zu erfüllen. Es ist wie wenn wir ein paar mehr Details benötigen, bevor wir antworten können."
        },
        "511": {
          "error": "Netzwerkauthentifizierung erforderlich",
          "message": "Sie müssen sich authentifizieren, um auf das Netzwerk zugreifen zu können. Es ist wie eine ID vor dem Betreten zu zeigen."
        },
      },
    },
    "it": {
      "contents": {
        "home": "home"
      },
      "statuscodes": {
        "" : {
          "error": "Errore sconosciuto",
          "message": "Si è verificato un errore sconosciuto"
        },
        "400": {
          "error": "Richiesta non valida",
          "message": "Spiacenti, non siamo riusciti a comprendere la tua richiesta a causa di un problema di sintassi."
        },
        "401": {
          "error": "Non autorizzato",
          "message": "Devi effettuare l'accesso per accedere a questa pagina."
        },
        "402": {
          "error": "Pagamento richiesto",
          "message": "È necessario un pagamento per accedere a questo servizio, ma non abbiamo ancora definito come funziona."
        },
        "403": {
          "error": "Vietato",
          "message": "Spiacenti, non hai il permesso di accedere a questa pagina."
        },
        "404": {
          "error": "Pagina non trovata",
          "message": "Oops! Ci dispiace, ma la pagina che stai cercando non è stata trovata."
        },
        "405": {
          "error": "Metodo non consentito",
          "message": "Spiacenti, ma non puoi utilizzare questo metodo per accedere a questa risorsa."
        },
        "406": {
          "error": "Non accettabile",
          "message": "Purtroppo non possiamo fornire le informazioni secondo le tue preferenze."
        },
        "407": {
          "error": "Autenticazione proxy richiesta",
          "message": "Per accedere a questa pagina, devi prima autenticarti tramite un proxy."
        },
        "408": {
          "error": "Richiesta scaduta",
          "message": "La tua richiesta ha impiegato troppo tempo a rispondere. Riprova più tardi."
        },
        "409": {
          "error": "Conflitto",
          "message": "La tua richiesta è in conflitto con lo stato attuale del server."
        },
        "410": {
          "error": "Andato",
          "message": "Spiacenti, ma la pagina che stai cercando è stata rimossa definitivamente."
        },
        "411": {
          "error": "Lunghezza richiesta",
          "message": "La tua richiesta deve specificare la lunghezza del contenuto."
        },
        "412": {
          "error": "Precondizione fallita",
          "message": "Sembra che alcune precondizioni della tua richiesta non siano soddisfatte."
        },
        "413": {
          "error": "Payload troppo grande",
          "message": "La tua richiesta è troppo grande per il nostro server. Riducilo e riprova."
        },
        "414": {
          "error": "URI troppo lungo",
          "message": "Spiacenti, ma l'indirizzo della pagina che stai cercando è troppo lungo."
        },
        "415": {
          "error": "Tipo di supporto non supportato",
          "message": "Spiacenti, non possiamo gestire il tipo di supporto richiesto."
        },
        "416": {
          "error": "Intervallo non soddisfacente",
          "message": "L'intervallo di dati richiesto non può essere soddisfatto."
        },
        "417": {
          "error": "Aspettativa fallita",
          "message": "Spiacenti, ma le tue aspettative specificate nella richiesta non possono essere soddisfatte dal nostro server."
        },
        "418": {
          "error": "Sono una teiera",
          "message": "Scherzo del server: Sono una teiera, quindi non posso preparare il caffè."
        },
        "421": {
          "error": "Richiesta errata",
          "message": "Oops! La tua richiesta è stata inviata a un server che non è in grado di rispondere. Controlla l'indirizzo che hai usato."
        },
        "422": {
          "error": "Entità non elaborabile",
          "message": "La tua richiesta è ben formulata, ma abbiamo riscontrato errori semantici. Controllalo e riprova."
        },
        "423": {
          "error": "Bloccato",
          "message": "La risorsa a cui stai cercando di accedere è attualmente bloccata."
        },
        "424": {
          "error": "Dipendenza fallita",
          "message": "La tua richiesta è fallita perché una richiesta precedente è fallita. Riprova più tardi."
        },
        "425": {
          "error": "Troppo presto",
          "message": "Non siamo ancora pronti a elaborare la tua richiesta per evitare che venga riprodotta. Riprova più tardi."
        },
        "426": {
          "error": "Aggiornamento richiesto",
          "message": "Il tuo protocollo corrente deve essere aggiornato per elaborare questa richiesta. Aggiorna e riprova."
        },
        "428": {
          "error": "Precondizione richiesta",
          "message": "La tua richiesta richiede alcune precondizioni. Controlla la tua richiesta e riprova."
        },
        "429": {
          "error": "Troppe richieste",
          "message": "Hai inviato troppe richieste in un breve periodo di tempo. Rallenta e riprova più tardi."
        },
        "431": {
          "error": "Campi di intestazione della richiesta troppo grandi",
          "message": "I campi di intestazione della tua richiesta sono troppo lunghi. Riducili e riprova."
        },
        "451": {
          "error": "Non disponibile per motivi legali",
          "message": "La pagina a cui stai cercando di accedere è bloccata per motivi legali."
        },
        "500": {
          "error": "Errore interno del server",
          "message": "Oh oh, il nostro server ha riscontrato un errore che non sa come gestire. Stiamo lavorando per risolverlo."
        },
        "501": {
          "error": "Non implementato",
          "message": "Il server non supporta la funzionalità richiesta per rispondere alla tua richiesta. Non sei tu, siamo noi."
        },
        "502": {
          "error": "Gateway non valido",
          "message": "Il server funge da gateway e ha ricevuto una risposta non valida da un server upstream. È come ricevere una risposta errata da un server intermedio."
        },
        "503": {
          "error": "Servizio non disponibile",
          "message": "Il server non è attualmente in grado di gestire la richiesta perché è in manutenzione o sovraccarico. Per favore torna più tardi."
        },
        "504": {
          "error": "Timeout del gateway",
          "message": "Il server, agendo come gateway, non ha ricevuto una risposta in tempo da un altro server. È come un problema di ritardo nella consegna."
        },
        "505": {
          "error": "Versione HTTP non supportata",
          "message": "La versione HTTP utilizzata nella richiesta non è supportata dal server. Potrebbe essere il momento di aggiornare."
        },
        "506": {
          "error": "Variante negozia anche",
          "message": "Il server ha una configurazione interna errata. È un po' come se avessimo un dibattito interno su come rispondere alla tua richiesta."
        },
        "507": {
          "error": "Spazio di archiviazione insufficiente",
          "message": "Il server non è in grado di archiviare la rappresentazione necessaria per completare la richiesta. Il nostro sottotetto è un po' pieno, per dirla in parole povere."
        },
        "508": {
          "error": "Rilevato loop",
          "message": "Il server ha rilevato un loop infinito nel tentativo di elaborare la richiesta. È come se girassimo in tondo all'infinito."
        },
        "510": {
          "error": "Non esteso",
          "message": "Sono necessarie informazioni aggiuntive per soddisfare la richiesta. È come se avessimo bisogno di un po' più di dettagli prima di poter rispondere."
        },
        "511": {
          "error": "Autenticazione di rete richiesta",
          "message": "È necessario autenticarsi per accedere alla rete. È come mostrare la tua carta d'identità prima di entrare."
        },
      },
    },
  }
</i18n>
<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useMeta } from "quasar";

export default defineComponent({
  name: "error-page",
  props: ["code"],
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    useMeta(() => {
      return {
        // sets document title
        title: t(`statuscodes.${route.params.code}.error`),
        // optional; sets final title as "Index Page - My Website", useful for multiple level meta
        titleTemplate: (title) => `AutoMarket - ${title}`,

        // meta tags
        meta: {
          description: {
            name: "description",
            content: t(`statuscodes.${route.params.code}.message`),
          },
          // keywords: { name: 'keywords', content: t('metaData.keywords') },
          // note: for Open Graph type metadata you will need to use SSR, to ensure page is rendered by the server
          ogTitle: {
            property: "og:title",
            // optional; similar to titleTemplate, but allows templating with other meta properties
            template(ogTitle) {
              return `AutoMarket - ${ogTitle}`;
            },
          },
        },
      };
    });

    return {
      route,
      t,
    };
  },
});
</script>
<style scoped lang="scss">
.styled-text {
  opacity: 0.5;
}
.text-isometric {
  font-weight: 900;
  font-size: 30vh;
  text-shadow: -1px -1px 0px $primary, -2px -2px 0px $primary,
    -3px -3px 0px $primary, -4px -4px 0px $primary, -5px -5px 0px $primary,
    -6px -6px 0px $primary, -7px -7px 0px $primary, -8px -8px 0px $primary,
    -9px -9px 0px $primary, -10px -10px 10px $primary;
}
.text-isometric-transform {
  transform: skewY(355deg);
  -webkit-transform: skewY(355deg);
  -moz-transform: skewY(355deg);
  -o-transform: skewY(355deg);
  -ms-transform: skewY(355deg);
}

@media screen and (max-width: 600px) {
  .text-isometric {
    font-size: 20vh;
  }
}
</style>
